import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import style from "./index.module.css"
import Grid from "../components/grid"

const Columns = ({ fluidImg, alt, first, last, link}) => {
  return (
    <div className={style.columnwrapper + " " + style.imghover}>
      <div className={style.columnbottom} data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
        <Link to={link}>
          <Img fluid={fluidImg} alt={alt}/>
          <div className={style.artistname}>{first}<br/>{last}</div>
        </Link>
      </div>
    </div>
  )
}

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Artists who changed the world"
        description="Descriptions of famous artists and their work"
        image="/logo.png"
        pathname="/"
        // Boolean indicating whether this is an article:
        // article
      />

      <div className={style.bubbles}>
        <div className={style.content}>
          <p className={style.mainTitle} data-sal="slide-up" data-sal-duration="1000"
             data-sal-easing="ease">Artists who changed the world</p>


          <Grid style={{ gridColumnGap: "32px" }}>
{/*Template*/}
            {/*<Columns*/}
              {/*first={""}*/}
              {/*last={""}*/}
              {/*alt={" portrait"}*/}
              {/*fluidImg={data..childImageSharp.fluid}*/}
            {/*/>*/}

            <Columns
              first={"Claude"}
              last={"Monet"}
              link={"/monet"}
              alt={"Claude Monet portrait"}
              fluidImg={data.monet.childImageSharp.fluid}
            />
            <Columns
              first={"Leonardo"}
              last={"da Vinci"}
              link={"/davinci"}
              alt={"Leonardo daVinci portrait"}
              fluidImg={data.davinci.childImageSharp.fluid}
            />

            <Columns
              first={"Mary"}
              last={"Cassatt"}
              link={"/cassatt"}

              alt={" portrait"}
              fluidImg={data.cassat.childImageSharp.fluid}
            />

            <Columns
              first={"Edgar"}
              last={"Degas"}
              link={"/degas"}
              alt={"Edgar Degas portrait"}
              fluidImg={data.degas.childImageSharp.fluid}
            />

            <Columns
              first={"Vincent"}
              last={"VanGogh"}
              link={"/vangogh"}
              alt={"Vincent VanGogh portrait"}
              fluidImg={data.vangogh.childImageSharp.fluid}
            />

            <Columns
            first={"Pablo"}
            last={"Picasso"}
            link={"/picasso"}
            alt={"Pablo Picasso portrait"}
            fluidImg={data.picasso.childImageSharp.fluid}
            />

          </Grid>
        </div>
        <>
          <p className={style.moreToCome} data-sal="slide-up" data-sal-duration="1000"
             data-sal-easing="ease">More to come!</p>
          </>
      </div>


    </Layout>
  )
}
export default IndexPage

export const query = graphql`
{
  monet: file(relativePath: {eq: "portraits/1.monet.jpg"}) {
  childImageSharp {
  fluid(maxWidth: 800) {
  ...GatsbyImageSharpFluid
}
}
}
  davinci: file(relativePath: {eq: "portraits/2.davinci.jpg"}) {
  childImageSharp {
  fluid(maxWidth: 800) {
  ...GatsbyImageSharpFluid
}
}
}
  cassat: file(relativePath: {eq: "portraits/3.cassat.jpg"}) {
  childImageSharp {
  fluid(maxWidth: 800) {
  ...GatsbyImageSharpFluid
}
}
}
  degas: file(relativePath: {eq: "portraits/5.degas.jpg"}) {
  childImageSharp {
  fluid(maxWidth: 800) {
  ...GatsbyImageSharpFluid
}
}
}
  vangogh: file(relativePath: {eq: "portraits/4.vangogh.jpg"}) {
  childImageSharp {
  fluid(maxWidth: 800) {
  ...GatsbyImageSharpFluid
}
}
}
  picasso: file(relativePath: {eq: "portraits/6.picasso.jpg"}) {
  childImageSharp {
  fluid(maxWidth: 800) {
  ...GatsbyImageSharpFluid
}
}
}
  okeefe: file(relativePath: {eq: "portraits/7.okeefe.jpg"}) {
  childImageSharp {
  fluid(maxWidth: 800) {
  ...GatsbyImageSharpFluid
}
}
}
  renoir: file(relativePath: {eq: "portraits/8.renoir.jpg"}) {
  childImageSharp {
  fluid(maxWidth: 800) {
  ...GatsbyImageSharpFluid
}
}
}
  seurat: file(relativePath: {eq: "portraits/9.seurat.jpg"}) {
  childImageSharp {
  fluid(maxWidth: 800) {
  ...GatsbyImageSharpFluid
}
}
}
}
`
